//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum MyProductsTableFilterColumn {
  name = 0,
  article = 1,
  barcodes = 2,
  syncOzon = 3,
  syncWb = 4,
  syncYandexMarket = 5,
  ozonArticle = 6,
  wbChrtId = 7,
  yandexMarketShopSku = 8
}
