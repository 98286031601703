//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

/**
* Intermediate status of an order.
*               Needed to convert MoySklad statuses to Ozon/WB and vice versa.
*/
export enum MyOrderStatus {
  /** Новый */
  New = 0,
  /** Подтверждён / На сборке */
  Confirmed = 1,
  /** Отгружен / Доставляется */
  Delivering = 3,
  /** Доставлен */
  Delivered = 4,
  /** Отменён продавцом */
  SellerCancelled = 5,
  /** Отменён покупателем */
  BuyerCancelled = 6,
  /** Возвращён */
  Returned = 8,
  /**
  * [Ozon] Не принят в СЦ.
  *               Пока что не используется.
  */
  ReturnDenied = 9
}
