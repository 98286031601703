//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { OzonOrdersTablePageModel } from '../Models/OzonOrdersTablePageModel';
import { OrdersTablePageOptions } from '../Models/OrdersTablePageOptions';
import { WbOrdersTablePageModel } from '../Models/WbOrdersTablePageModel';
import { YandexMarketOrdersTablePageModel } from '../Models/YandexMarketOrdersTablePageModel';
import { MoySkladOrdersTablePageModel } from '../Models/MoySkladOrdersTablePageModel';
import { MoySkladOrdersTablePageOptions } from '../Models/MoySkladOrdersTablePageOptions';
import { WbSupplyModel } from '../Models/WbSupplyModel';
import { SetWbOrdersSupplyModel } from '../Models/SetWbOrdersSupplyModel';
import { CreateWbOrdersSupplyModel } from '../Models/CreateWbOrdersSupplyModel';

import { boundClass } from 'autobind-decorator';
import { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
import { encodeUrlParameter, getAxiosData } from '../../../common/urlUtility';
@boundClass export class OrderController
{
  constructor (axios: AxiosInstance)
  {
    this.axios = axios
  }
  
  private readonly axios: AxiosInstance
  
  getOzonPage(options: OrdersTablePageOptions, _config?: AxiosRequestConfig) : Promise<OzonOrdersTablePageModel>
  {
    return this.axios.post<OzonOrdersTablePageModel>(`Order/GetOzonPage`, options, _config).then(getAxiosData)
  }
  

  getWbPage(options: OrdersTablePageOptions, _config?: AxiosRequestConfig) : Promise<WbOrdersTablePageModel>
  {
    return this.axios.post<WbOrdersTablePageModel>(`Order/GetWbPage`, options, _config).then(getAxiosData)
  }
  

  getYandexMarketPage(options: OrdersTablePageOptions, _config?: AxiosRequestConfig) : Promise<YandexMarketOrdersTablePageModel>
  {
    return this.axios.post<YandexMarketOrdersTablePageModel>(`Order/GetYandexMarketPage`, options, _config).then(getAxiosData)
  }
  

  getMoySkladPage(options: MoySkladOrdersTablePageOptions, _config?: AxiosRequestConfig) : Promise<MoySkladOrdersTablePageModel>
  {
    return this.axios.post<MoySkladOrdersTablePageModel>(`Order/GetMoySkladPage`, options, _config).then(getAxiosData)
  }
  

  pullOzon(ozonOrderId: number, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Order/PullOzon/${encodeUrlParameter(ozonOrderId)}`, null, _config).then(getAxiosData)
  }
  

  pullWb(wbOrderId: number, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Order/PullWb/${encodeUrlParameter(wbOrderId)}`, null, _config).then(getAxiosData)
  }
  

  pullYandexMarket(yandexMarketOrderId: number, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Order/PullYandexMarket/${encodeUrlParameter(yandexMarketOrderId)}`, null, _config).then(getAxiosData)
  }
  

  pullOzonOrdersWithErrors(ozonAccountId: number, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Order/PullOzonOrdersWithErrors/${encodeUrlParameter(ozonAccountId)}`, null, _config).then(getAxiosData)
  }
  

  pullWbOrdersWithErrors(wbAccountId: number, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Order/PullWbOrdersWithErrors/${encodeUrlParameter(wbAccountId)}`, null, _config).then(getAxiosData)
  }
  

  pullYmOrdersWithErrors(yandexMarketAccountId: number, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Order/PullYmOrdersWithErrors/${encodeUrlParameter(yandexMarketAccountId)}`, null, _config).then(getAxiosData)
  }
  

  pullMoySkladOrdersWithErrors(_config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Order/PullMoySkladOrdersWithErrors`, null, _config).then(getAxiosData)
  }
  

  pullMoySklad(msOrderId: number, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Order/PullMoySklad/${encodeUrlParameter(msOrderId)}`, null, _config).then(getAxiosData)
  }
  

  getActiveWbSupplies(wbAccountId: number, _config?: AxiosRequestConfig) : Promise<WbSupplyModel[]>
  {
    return this.axios.get<WbSupplyModel[]>(`Order/GetActiveWbSupplies?wbAccountId=${encodeUrlParameter(wbAccountId)}`, _config).then(getAxiosData)
  }
  

  getClosedWbSupplies(wbAccountId: number, _config?: AxiosRequestConfig) : Promise<WbSupplyModel[]>
  {
    return this.axios.get<WbSupplyModel[]>(`Order/GetClosedWbSupplies?wbAccountId=${encodeUrlParameter(wbAccountId)}`, _config).then(getAxiosData)
  }
  

  setWbOrdersSupply(model: SetWbOrdersSupplyModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Order/SetWbOrdersSupply`, model, _config).then(getAxiosData)
  }
  

  createWbSupplyWithOrders(model: CreateWbOrdersSupplyModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Order/CreateWbSupplyWithOrders`, model, _config).then(getAxiosData)
  }
  

  /** Gets the full relative URL for the action */
  getActiveWbSuppliesUrl(wbAccountId: number, _config?: AxiosRequestConfig) : string
  {
    return `/api/Order/GetActiveWbSupplies?wbAccountId=${encodeUrlParameter(wbAccountId)}`
  }
  

  /** Gets the full relative URL for the action */
  getClosedWbSuppliesUrl(wbAccountId: number, _config?: AxiosRequestConfig) : string
  {
    return `/api/Order/GetClosedWbSupplies?wbAccountId=${encodeUrlParameter(wbAccountId)}`
  }
  

}
