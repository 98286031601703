//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ProductDiffType {
  None = 0,
  User = 1,
  Ozon = 2,
  Wb = 4,
  OdinEs = 8,
  MoySklad = 16,
  Import = 32,
  YandexMarket = 64
}
