//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum MoySkladAppType {
  Ozon = 1,
  Wb = 2,
  OzonAndWb = 3,
  Mini = 4,
  Ym = 5
}
