//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

/** Values match the entity type in MS API, except the API is in lower case. */
export enum MsProductType {
  /** Товар https://dev.moysklad.ru/doc/api/remap/1.2/dictionaries/#suschnosti-towar */
  Product = 0,
  /** Комплект https://dev.moysklad.ru/doc/api/remap/1.2/dictionaries/#suschnosti-komplekt */
  Bundle = 1,
  /** Модификация https://dev.moysklad.ru/doc/api/remap/1.2/dictionaries/#suschnosti-modifikaciq */
  Variant = 2
}
