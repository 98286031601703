//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum YandexMarketProductProp {
  ShopSku = 1,
  Name = 2,
  VendorCode = 3,
  Barcode = 4
}
