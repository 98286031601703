//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { ImportProductsOptionsModel } from '../Models/ImportModels/ImportProductsModels/ImportProductsOptionsModel';
import { ImportStocksOptionsModel } from '../Models/ImportModels/ImportStocksModels/ImportStocksOptionsModel';

import { boundClass } from 'autobind-decorator';
import { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
import { encodeUrlParameter, getAxiosData } from '../../../common/urlUtility';
@boundClass export class ImportController
{
  
  private readonly axios: AxiosInstance
  
  constructor (axios: AxiosInstance)
  {
    this.axios = axios
  }
  importProductsOzon(ozonAccountId: number, options: ImportProductsOptionsModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Import/ImportProductsOzon/${encodeUrlParameter(ozonAccountId)}`, options, _config).then(getAxiosData)
  }
  

  importProductsWb(wbAccountId: number, options: ImportProductsOptionsModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Import/ImportProductsWb/${encodeUrlParameter(wbAccountId)}`, options, _config).then(getAxiosData)
  }
  

  importProductsYandexMarket(yandexMarketAccountId: number, options: ImportProductsOptionsModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Import/ImportProductsYandexMarket/${encodeUrlParameter(yandexMarketAccountId)}`, options, _config).then(getAxiosData)
  }
  

  importStocksOzon(ozonAccountId: number, options: ImportStocksOptionsModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Import/ImportStocksOzon/${encodeUrlParameter(ozonAccountId)}`, options, _config).then(getAxiosData)
  }
  

  importStocksWb(wbAccountId: number, options: ImportStocksOptionsModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Import/ImportStocksWb/${encodeUrlParameter(wbAccountId)}`, options, _config).then(getAxiosData)
  }
  

  importStocksYandexMarket(yandexMarketAccountId: number, options: ImportStocksOptionsModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Import/ImportStocksYandexMarket/${encodeUrlParameter(yandexMarketAccountId)}`, options, _config).then(getAxiosData)
  }
  

}
