//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

/**
* Перечисление типов моделей Wildberries:
*               <para>FBW - Fulfillment by Wildberries (хранение и логистика полностью за маркетплейсом).</para><para>FBS - Fulfillment by Seller (хранение на складе продавца, но логистика от Wildberries).</para><para>DBS - Delivery by Seller (Продавец самостоятельно хранит товар и организует доставку).</para>
*/
export enum WbDeliveryType {
  /** Fulfillment by Wildberries (Маркетплейс отвечает за хранение и доставку). */
  FBW = 0,
  /** Fulfillment by Seller (Продавец хранит товар, но доставка осуществляется через Wildberries). */
  FBS = 1,
  /** Delivery by Seller (Продавец самостоятельно хранит товар и организует доставку). */
  DBS = 2,
  /** Delivery by Wildberries courier (Доставка курьером WB) */
  WbGo = 3
}
