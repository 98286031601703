//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

/**
* TODO: Get rid of this enum? It's used only to double-convert strings from frontend.
*                 Or reuse with <see cref="T:mpsklad_core.Entity.YandexMarket.Enums.YandexMarketOrderCommissionType" />?
*               Типы удержаний
*/
export enum MoySkladOrderFeeType {
  /** Комиссия */
  Commission = 0,
  /** Магистраль */
  Magistral = 1,
  /** Последняя миля */
  LastMile = 2,
  /** Сборка */
  Assembly = 3,
  /** Размещение товара на маркетплейсе */
  Fee = 4,
  /** Плата за участие в программе лояльности */
  LoyaltyParticipationFee = 5,
  /** Доставка покупателю (FBY, FBS). Для DBS и Экспресс — если заказ возвращается через логистику Маркета. */
  DeliveryToCustomer = 6,
  /** Экспресс-доставка покупателю (Экспресс). */
  ExpressDeliveryToCustomer = 7,
  /** Плата за буст продаж */
  AuctionPromotion = 8,
  /** Прием платежа покупателя */
  Agency = 9,
  /** Перевод платежа покупателя */
  PaymentTransfer = 10,
  /** Хранение невыкупов и возвратов (FBS). Для DBS и Экспресс — если заказ возвращается через логистику Маркета. */
  ReturnedOrdersStorage = 11,
  /** Обработка заказа (FBS) */
  Sorting = 12,
  /** Организация забора заказов со склада продавца (FBS). */
  IntakeSorting = 13,
  /** Обработка заказов на складе (FBS). Для DBS и Экспресс — если заказ возвращается через логистику Маркета. */
  ReturnProcessing = 14,
  /** Вознаграждение за продажу невывезенных товаров */
  IlliquidGoodsSale = 15
}
