//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum UsersTableOrderColumn {
  createdAt = 0,
  hasPaidMsApp = 1,
  lastOrderDateUnix = 2,
  ozonAccountCount = 3,
  wbAccountCount = 4,
  yandexMarketAccountCount = 5,
  allowSync = 6,
  isUsingFakeApi = 7,
  msTariff = 8
}
