//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum MyProductsTableOrderColumn {
  name = 0,
  createdAt = 1,
  ConnectOzon = 2,
  ConnectWb = 3,
  ConnectYandexMarket = 4,
  type = 5,
  code = 6,
  article = 7,
  price = 8,
  basePrice = 9,
  ozonMsMinimalPrice = 10,
  ozonMsPrice = 11,
  ozonMsBasePrice = 12,
  wbMsPrice = 13,
  wbMsBasePrice = 14,
  yandexMarketMsPrice = 15,
  yandexMarketMsBasePrice = 16,
  ozonSyncEnabled = 17,
  ozonArticle = 18,
  ozonIsVisible = 19,
  wbSyncEnabled = 20,
  wbChrtId = 21,
  yandexMarketSyncEnabled = 22,
  yandexMarketShopSku = 23
}
